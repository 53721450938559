import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__code"
};
const _hoisted_2 = {
    class: "devtools__actions"
};
const _hoisted_3 = {
    class: "devtools__code"
};
const _hoisted_4 = {
    class: "devtools__actions"
};
const _hoisted_5 = {
    class: "devtools__code"
};
const _hoisted_6 = {
    class: "devtools__actions"
};
import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { assert } from '@leon-hub/guards';
import { getLocationOrigin } from '@leon-hub/service-locator-env';
import { TextInput } from 'web/src/components/Input';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { getCmsKeys, getCmsParams } from 'web/src/modules/cms/utils';
import { useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { useCmsStore } from 'web/src/modules/cms/store';
import { isRouteData } from './utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SeoTab',
    setup (__props) {
        const router = useRouter();
        const i18nStore = useI18nStore();
        const routerStore = useRouterStore();
        const locale = toRef(i18nStore, 'locale');
        const seoConfigs = toRef(routerStore, 'seoConfigs');
        const cmsStore = useCmsStore();
        const siteConfigStore = useSiteConfigStore();
        const isSeoBetCmsSpintaxEnabled = toRef(siteConfigStore, 'isSeoBetCmsSpintaxEnabled');
        const url = ref('');
        const isCmsTextLoading = ref(false);
        const greenButtonProps = {
            kind: ButtonKind.PRIMARY,
            height: ButtonHeight.LARGE,
            fullWidth: false
        };
        const routeData = computed(()=>{
            const value = url.value ? router.resolve(url.value.replace(`${getLocationOrigin()}/`, '').replace(locale.value.replace('_', '-').toLowerCase(), '')) : {
                name: '',
                params: {}
            };
            isRouteData(value);
            return value;
        });
        const cmsKeys = computed(()=>getCmsKeys(routeData.value.name, routeData.value.params, seoConfigs.value));
        const cmsParams = computed(()=>getCmsParams(routeData.value.name, routeData.value.params, seoConfigs.value));
        const currentCmsKey = computed(()=>cmsKeys.value[0]);
        const cmsText = cmsStore.getCmsContent(isSeoBetCmsSpintaxEnabled, currentCmsKey);
        function onUrlChange(event) {
            url.value = event.target.value;
        }
        async function getCmsText() {
            if (!cmsKeys.value.length) return;
            isCmsTextLoading.value = true;
            try {
                cmsStore.resetCmsSpintaxData();
                if (isSeoBetCmsSpintaxEnabled.value) await cmsStore.fetchCmsContentSpintaxBatchTranslations({
                    keys: cmsKeys.value,
                    cmsParams: cmsParams.value,
                    silent: false
                });
                else await cmsStore.fetchCmsContentTranslation({
                    key: currentCmsKey.value,
                    silent: false
                });
            } catch (rawError) {
                isCmsTextLoading.value = false;
                throw rawError;
            }
            isCmsTextLoading.value = false;
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _cache[5] || (_cache[5] = _createElementVNode("h4", null, "URL:", -1)),
                _createVNode(_unref(TextInput), {
                    name: "url",
                    onInput: onUrlChange
                }),
                _createElementVNode("div", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("h4", null, "CMS Keys:", -1)),
                    _createElementVNode("code", _hoisted_1, _toDisplayString(cmsKeys.value), 1),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(VButton, _mergeProps(greenButtonProps, {
                            key: "copyNetworkErrors",
                            style: {
                                'margin-right': '50px'
                            },
                            class: "devtools__actions-button",
                            label: "Copy",
                            onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$copyToClipboard({
                                    data: currentCmsKey.value,
                                    notificationText: 'Copied to clipboard'
                                }))
                        }), null, 16, [
                            "style"
                        ])
                    ])
                ]),
                _createElementVNode("div", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("h4", null, "CMS Params:", -1)),
                    _createElementVNode("code", _hoisted_3, _toDisplayString(cmsParams.value), 1),
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(VButton, _mergeProps(greenButtonProps, {
                            key: "copyNetworkErrors",
                            style: {
                                'margin-right': '50px'
                            },
                            class: "devtools__actions-button",
                            label: "Copy",
                            onClick: _cache[1] || (_cache[1] = ($event)=>_ctx.$copyToClipboard({
                                    data: JSON.stringify(cmsParams.value),
                                    notificationText: 'Copied to clipboard'
                                }))
                        }), null, 16, [
                            "style"
                        ])
                    ])
                ]),
                _createElementVNode("code", _hoisted_5, _toDisplayString(_unref(cmsText)), 1),
                _createElementVNode("div", _hoisted_6, [
                    _createVNode(VButton, _mergeProps(greenButtonProps, {
                        key: "getCmsText",
                        "is-loading": isCmsTextLoading.value,
                        class: "devtools__actions-button",
                        label: "Get CMS Text",
                        onClick: getCmsText
                    }), null, 16, [
                        "is-loading"
                    ]),
                    _createVNode(VButton, _mergeProps(greenButtonProps, {
                        key: "copyCmsText",
                        style: {
                            'margin-right': '50px'
                        },
                        class: "devtools__actions-button",
                        label: "Copy",
                        onClick: _cache[2] || (_cache[2] = ($event)=>_ctx.$copyToClipboard({
                                data: JSON.stringify(_unref(cmsText)),
                                notificationText: 'Copied to clipboard'
                            }))
                    }), null, 16, [
                        "style"
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'SeoTab'
                ]
            ]);
        };
    }
});
