import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useIdentityStore } from 'web/src/modules/identity/store';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { DeviceFingerprintType } from 'web/src/modules/identity/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FingerPrintTab',
    setup (__props) {
        const identityStore = useIdentityStore();
        const siteConfigStore = useSiteConfigStore();
        const isFingerprintJsEnabled = toRef(siteConfigStore, 'isFingerprintJsEnabled');
        const isBrowserFingerprintEnabled = toRef(siteConfigStore, 'isBrowserFingerprintEnabled');
        const deviceFingerprints = toRef(identityStore, 'deviceFingerprints');
        const { getDeviceFingerprint } = identityStore;
        const deviceFingerprintTypes = [
            DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER,
            DeviceFingerprintType.DEVICE_FINGERPRINT_JS
        ];
        const eventButtonProps = {
            kind: ButtonKind.YELLOW,
            height: ButtonHeight.SMALL,
            fullWidth: false
        };
        function isFingerprintTypeEnabled(fpType) {
            return fpType === DeviceFingerprintType.DEVICE_FINGERPRINT_JS && isFingerprintJsEnabled.value || fpType === DeviceFingerprintType.DEVICE_FINGERPRINT_BROWSER && isBrowserFingerprintEnabled.value;
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(deviceFingerprints.value, (value, name)=>(_openBlock(), _createElementBlock("code", {
                            key: name,
                            class: "devtools__code"
                        }, [
                            _createElementVNode("strong", null, _toDisplayString(name.toUpperCase()), 1),
                            _createTextVNode(": " + _toDisplayString(value), 1)
                        ]))), 128))
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(deviceFingerprintTypes), (fpType)=>(_openBlock(), _createElementBlock("div", {
                        key: fpType,
                        style: {
                            "margin-top": "10px"
                        }
                    }, [
                        _createVNode(VButton, _mergeProps({
                            class: "devtools__actions-button",
                            ref_for: true
                        }, eventButtonProps, {
                            label: fpType,
                            disabled: !isFingerprintTypeEnabled(fpType),
                            onClick: ($event)=>_unref(getDeviceFingerprint)(fpType)
                        }), null, 16, [
                            "label",
                            "disabled",
                            "onClick"
                        ])
                    ]))), 128))
            ])), [
                [
                    _directive_auto_id,
                    'FingerPrintTab'
                ]
            ]);
        };
    }
});
