import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__grid-fill"
};
const _hoisted_3 = {
    key: 1
};
const _hoisted_4 = {
    class: "devtools__code"
};
const _hoisted_5 = {
    key: 2
};
const _hoisted_7 = {
    class: "devtools__code"
};
const _hoisted_8 = {
    key: 4
};
const _hoisted_17 = {
    class: "devtools__code"
};
const _hoisted_18 = {
    key: 13
};
const _hoisted_21 = {
    class: "devtools__code"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'TemplatesTab3',
    setup (__props) {
        function platform() {
            "1";
            return 'WEB';
        }
        function product() {
            "1";
            return 'LI';
        }
        function skin() {
            "1";
            return 'DEFAULT';
        }
        function layout() {
            "1";
            return 'DESKTOP';
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _cache[20] || (_cache[20] = _createElementVNode("h3", null, "Templates:", -1)),
                _createElementVNode("div", _hoisted_1, [
                    _createCommentVNode("", true),
                    (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
                        _createElementVNode("h4", null, "VUE_APP_PLATFORM:", -1),
                        _createElementVNode("code", {
                            class: "devtools__code"
                        }, "WEB", -1)
                    ]))),
                    _createElementVNode("div", null, [
                        _cache[2] || (_cache[2] = _createElementVNode("h4", null, "VUE_APP_PLATFORM JS:", -1)),
                        _createElementVNode("code", _hoisted_4, _toDisplayString(platform()), 1)
                    ]),
                    (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
                        _createElementVNode("h4", null, "VUE_APP_PRODUCT:", -1),
                        _createElementVNode("code", {
                            class: "devtools__code"
                        }, "LI", -1)
                    ]))),
                    _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                        _cache[5] || (_cache[5] = _createElementVNode("h4", null, "VUE_APP_PRODUCT JS:", -1)),
                        _createElementVNode("code", _hoisted_7, _toDisplayString(product()), 1)
                    ]),
                    (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[6] || (_cache[6] = [
                        _createElementVNode("h4", null, "VUE_APP_SKIN:", -1),
                        _createElementVNode("code", {
                            class: "devtools__code"
                        }, "DEFAULT", -1)
                    ]))),
                    _createCommentVNode("", true),
                    _createCommentVNode("", true),
                    _createCommentVNode("", true),
                    _createCommentVNode("", true),
                    _createCommentVNode("", true),
                    _createCommentVNode("", true),
                    _createCommentVNode("", true),
                    _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                        _cache[15] || (_cache[15] = _createElementVNode("h4", null, "VUE_APP_SKIN JS:", -1)),
                        _createElementVNode("code", _hoisted_17, _toDisplayString(skin()), 1)
                    ]),
                    (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[16] || (_cache[16] = [
                        _createElementVNode("h4", null, "VUE_APP_LAYOUT:", -1),
                        _createElementVNode("code", {
                            class: "devtools__code"
                        }, "DESKTOP", -1)
                    ]))),
                    _createCommentVNode("", true),
                    _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                        _cache[19] || (_cache[19] = _createElementVNode("h4", null, "VUE_APP_LAYOUT JS:", -1)),
                        _createElementVNode("code", _hoisted_21, _toDisplayString(layout()), 1)
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'TemplatesTab3'
                ]
            ]);
        };
    }
});
