import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__actions"
};
import { Theme } from '@leon-hub/api-sdk';
import { useEventsBus } from '@leon-hub/event-bus';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useThemeStore } from 'web/src/modules/theme/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EventsTab',
    setup (__props) {
        const themeStore = useThemeStore();
        const eventBus = useEventsBus();
        const eventButtons = [];
        const eventButtonProps = {
            kind: ButtonKind.YELLOW,
            height: ButtonHeight.SMALL,
            fullWidth: false
        };
        function emulateEvent(event) {
            // eslint-disable-next-line no-console
            console.log('Emitting event=', event.toString());
            eventBus.emit(event, {});
        }
        function onResetAutoTheme() {
            themeStore.recalculateTheme();
            themeStore.changeTheme(Theme.AUTO);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Emulate events:", -1)),
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(VButton, _mergeProps(eventButtonProps, {
                        label: "Reset auto theme",
                        class: "devtools__actions-button",
                        onClick: onResetAutoTheme
                    }), null, 16),
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(eventButtons, (eventButton)=>_createVNode(VButton, _mergeProps({
                            ref_for: true
                        }, eventButtonProps, {
                            key: eventButton.key,
                            class: "devtools__actions-button",
                            label: eventButton.label,
                            onClick: ($event)=>emulateEvent(eventButton.event)
                        }), null, 16, [
                            "label",
                            "onClick"
                        ])), 64))
                ])
            ])), [
                [
                    _directive_auto_id,
                    'EventsTab'
                ]
            ]);
        };
    }
});
