import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__code"
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'BtrTab',
    setup (__props) {
        // Empty
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Missing BTR key:", -1)),
                _createElementVNode("div", null, [
                    _createElementVNode("code", _hoisted_1, _toDisplayString(_ctx.$t('MISSING_BTR_KEY')), 1)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'BtrTab'
                ]
            ]);
        };
    }
});
