import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__code"
};
import { isLocalStorageAvailable, localStorageManager } from '@leon-hub/local-storage';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LocalStorageTab',
    setup (__props) {
        const localStorageData = {};
        const isLCAvailable = isLocalStorageAvailable();
        if (isLCAvailable) for(let i = 0; i < localStorageManager.length; i += 1){
            const key = localStorageManager.key(i);
            if (key) localStorageData[key] = localStorageManager.getItem(key);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", null, [
                    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Is LocalStorage available:", -1)),
                    _createElementVNode("code", _hoisted_1, _toDisplayString(_unref(isLCAvailable)), 1)
                ]),
                _createElementVNode("div", {
                    class: "devtools__code"
                }, [
                    _cache[1] || (_cache[1] = _createElementVNode("h4", null, "LocalStorage data:", -1)),
                    _createElementVNode("pre", null, _toDisplayString(localStorageData))
                ])
            ])), [
                [
                    _directive_auto_id,
                    'LocalStorageTab'
                ]
            ]);
        };
    }
});
