import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__actions"
};
const _hoisted_2 = {
    class: "devtools__actions"
};
const _hoisted_3 = {
    class: "devtools__code"
};
const _hoisted_4 = {
    class: "devtools__actions"
};
const _hoisted_5 = {
    key: 0
};
const _hoisted_6 = {
    class: "devtools__code"
};
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useErrorTab } from 'web/src/modules/dev-tools/components/ErrorsTab/composables';
import { getInitSentryOptions } from 'web/src/modules/core/apps/main/utils/getInitSentryOptions';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ErrorsTab',
    setup (__props) {
        const { errorButtons, onEmulateError, onEmulateVueError, onSendErrorLog, networkErrorsData, onClearNetworkErrors, onEmulateNativeCrash } = useErrorTab();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _cache[7] || (_cache[7] = _createElementVNode("h4", null, "Emulate errors:", -1)),
                _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(errorButtons), (errorButton)=>(_openBlock(), _createBlock(VButton, _mergeProps({
                            ref_for: true
                        }, {
                            kind: _unref(ButtonKind).RED,
                            height: _unref(ButtonHeight).SMALL,
                            fullWidth: false
                        }, {
                            key: errorButton.key,
                            class: "devtools__actions-button",
                            label: errorButton.label,
                            onClick: ($event)=>_unref(onEmulateError)(errorButton)
                        }), null, 16, [
                            "label",
                            "onClick"
                        ]))), 128)),
                    _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Emulate Vue Errors:", -1)),
                    _createVNode(VButton, _mergeProps({
                        kind: _unref(ButtonKind).RED,
                        height: _unref(ButtonHeight).SMALL,
                        fullWidth: false
                    }, {
                        key: "VueComponentError",
                        class: "devtools__actions-button",
                        label: "Emulate Vue Component Error",
                        onClick: _cache[0] || (_cache[0] = ($event)=>_unref(onEmulateVueError)())
                    }), null, 16),
                    _createVNode(VButton, _mergeProps({
                        kind: _unref(ButtonKind).RED,
                        height: _unref(ButtonHeight).SMALL,
                        fullWidth: false
                    }, {
                        key: "VueIgnoredError",
                        class: "devtools__actions-button",
                        label: "Emulate Ignored Vue Error",
                        onClick: _cache[1] || (_cache[1] = ($event)=>_unref(onEmulateVueError)(true))
                    }), null, 16)
                ]),
                _createElementVNode("div", null, [
                    _cache[4] || (_cache[4] = _createElementVNode("h4", null, "Logging:", -1)),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(VButton, _mergeProps({
                            kind: _unref(ButtonKind).PRIMARY,
                            height: _unref(ButtonHeight).LARGE,
                            fullWidth: false
                        }, {
                            key: "sendErrorLog",
                            class: "devtools__actions-button",
                            label: "Send Error Log",
                            onClick: _unref(onSendErrorLog)
                        }), null, 16, [
                            "onClick"
                        ])
                    ])
                ]),
                _createElementVNode("div", null, [
                    _cache[5] || (_cache[5] = _createElementVNode("h4", null, "Network errors:", -1)),
                    _createElementVNode("code", _hoisted_3, _toDisplayString(_unref(networkErrorsData)), 1),
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(VButton, _mergeProps({
                            kind: _unref(ButtonKind).PRIMARY,
                            height: _unref(ButtonHeight).LARGE,
                            fullWidth: false
                        }, {
                            key: "copyNetworkErrors",
                            style: {
                                'margin-right': '50px'
                            },
                            class: "devtools__actions-button",
                            label: "Copy",
                            onClick: _cache[2] || (_cache[2] = ($event)=>_ctx.$copyToClipboard({
                                    data: JSON.stringify(_unref(networkErrorsData)),
                                    notificationText: 'Copied to clipboard'
                                }))
                        }), null, 16, [
                            "style"
                        ]),
                        _createVNode(VButton, _mergeProps({
                            kind: _unref(ButtonKind).RED,
                            height: _unref(ButtonHeight).LARGE,
                            fullWidth: false
                        }, {
                            key: "clearNetworkErrors",
                            class: "devtools__actions-button",
                            label: "Clear",
                            onClick: _unref(onClearNetworkErrors)
                        }), null, 16, [
                            "onClick"
                        ])
                    ])
                ]),
                (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _cache[6] || (_cache[6] = _createElementVNode("h4", null, "Sentry Config", -1)),
                    _createElementVNode("pre", _hoisted_6, "        " + _toDisplayString(_unref(getInitSentryOptions)()) + "\n      ", 1)
                ]))
            ])), [
                [
                    _directive_auto_id,
                    'ErrorsTab'
                ]
            ]);
        };
    }
});
