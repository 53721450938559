import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { VCheckbox } from 'web/src/components/Checkbox';
import { useDebugTab, useAutomationPluginToggler } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DebugTab',
    setup (__props) {
        const { isGeneralDebugEnabled, isPostMessageBusDebugEnabled, isValidationDisabled, isVueDevToolEnabled, isAdvancedHttpRequestsDisabled, toggleGeneralDebugMode, togglePostMessageDebugMode, toggleFormValidationMode, toggleAdvancedHttpRequests, enableVueDevTools } = useDebugTab();
        const { automationPluginState, toggleAutomationPluginState } = useAutomationPluginToggler();
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("div", null, [
                    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Debug mode:", -1)),
                    _createVNode(_unref(VCheckbox), {
                        id: "debug-toggler",
                        name: "debugEnabler",
                        label: "Debug enabled",
                        checked: _unref(isGeneralDebugEnabled),
                        onChange: _unref(toggleGeneralDebugMode)
                    }, null, 8, [
                        "checked",
                        "onChange"
                    ]),
                    _createCommentVNode("", true),
                    _createVNode(_unref(VCheckbox), {
                        id: "vue-dev-tools-toggler",
                        name: "vueDevToolsToggler",
                        label: "Vue dev-tools enabled",
                        disabled: _unref(isVueDevToolEnabled),
                        checked: _unref(isVueDevToolEnabled),
                        onChange: _unref(enableVueDevTools)
                    }, null, 8, [
                        "disabled",
                        "checked",
                        "onChange"
                    ]),
                    _createVNode(_unref(VCheckbox), {
                        id: "post-message-bus-debug-toggler",
                        name: "pmbDebugToggler",
                        label: "Post message bus debug enabled",
                        checked: _unref(isPostMessageBusDebugEnabled),
                        onChange: _unref(togglePostMessageDebugMode)
                    }, null, 8, [
                        "checked",
                        "onChange"
                    ]),
                    _createVNode(_unref(VCheckbox), {
                        id: "automation-plugin-toggler",
                        name: "automationPluginState",
                        label: "Enable automatic root id generation for components",
                        checked: _unref(automationPluginState),
                        disabled: false,
                        onChange: _unref(toggleAutomationPluginState)
                    }, null, 8, [
                        "checked",
                        "disabled",
                        "onChange"
                    ])
                ]),
                _createElementVNode("div", null, [
                    _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Disable form validation on frontend:", -1)),
                    _createVNode(_unref(VCheckbox), {
                        id: "validation-toggler",
                        name: "validationToggler",
                        label: "Validation disabled",
                        checked: _unref(isValidationDisabled),
                        onChange: _unref(toggleFormValidationMode)
                    }, null, 8, [
                        "checked",
                        "onChange"
                    ])
                ])
            ], 64));
    }
});
