import { computed, onMounted, ref } from 'vue';
import { getLocationOrigin } from '@leon-hub/service-locator-env';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
export default function useGeneralTab() {
    const stat = ref('Loading application stats data…');
    const appChunkName = document.querySelector('script[src*=app]')?.getAttribute('src')?.split('/').slice(-1)[0] ?? '';
    const { locale } = useI18nLocale();
    const { $translate, setLanguageTag } = useI18n();
    const { convertToBaseError } = useErrorsConverter();
    const localeOptions = [
        {
            label: 'ru_RU',
            value: 'ru_RU'
        },
        {
            label: 'en_US',
            value: 'en_US'
        },
        {
            label: 'en_IN',
            value: 'en_IN'
        }
    ];
    const statUrl = computed(()=>`${getLocationOrigin()}/js/stat/desktop/_stat.json`);
    const translation = computed(()=>{
        "1";
        return $translate('WEB2_DEVTOOLS_LI').value;
    });
    onMounted(mounted);
    function mounted() {
        "1";
        fetchStat();
    }
    async function fetchStat() {
        try {
            const response = await fetch(`${statUrl.value}?nocache=${Math.random()}`);
            stat.value = await response.json();
        } catch (error) {
            stat.value = `Error: ${convertToBaseError(error)}`;
        }
    }
    function changeLocale(event) {
        setLanguageTag({
            languageTag: event.target.value
        });
    }
    return {
        stat,
        appChunkName,
        statUrl,
        localeOptions,
        locale,
        changeLocale,
        translation
    };
}
