import { ApiConnectionError, ApiTechnicalError, ApiServiceUnavailableError, ApiRequestAbortedError, GqlApiAccessDeniedError } from '@leon-hub/api';
import { AccessDeniedRemoteApiExceptionCode } from '@leon-hub/api-sdk';
import { AppError, AppIgnoredError } from '@leon-hub/app-errors';
import { ChunkLoadError } from '@leon-hub/routing-config';
import CaptchaServiceError from 'web/src/modules/captcha/services/errors/CaptchaServiceError';
export default function getErrorButtonItems() {
    return [
        {
            key: 'ApiConnectionError',
            label: 'Api Connection Error',
            error: new ApiConnectionError({
                silent: false
            })
        },
        {
            key: 'ApiTechnicalError',
            label: 'Api Technical Error',
            error: new ApiTechnicalError({
                silent: false,
                interactive: true
            })
        },
        {
            key: 'ApiServiceUnavailableError',
            label: 'Api Service Unavailable Error',
            error: new ApiServiceUnavailableError({
                silent: false,
                interactive: true
            })
        },
        {
            key: 'ApiRequestAbortedError',
            label: 'Api Request Aborted Error',
            error: new ApiRequestAbortedError({
                silent: false,
                interactive: true
            })
        },
        {
            key: 'AccessDeniedError',
            label: 'Access Denied Error (authorized only)',
            error: new GqlApiAccessDeniedError({
                silent: false,
                extensions: {
                    errorCode: AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED
                }
            })
        },
        {
            key: 'ApplicationCustomError',
            label: 'Application Custom Error',
            error: new Error('Some custom error occured')
        },
        {
            key: 'UnhandledPromiseRejection',
            label: 'Unhandled Promise Rejection',
            error: (()=>{
                const error = new AppError({
                    message: 'Unhandled Promise Rejection'
                });
                error.addLogMetaData('ErrorMetaKey', 'ErrorMetaValue');
                return error;
            })()
        },
        {
            key: 'IgnoredError',
            label: 'Ignored Error',
            error: new AppIgnoredError()
        },
        {
            key: 'ChunkLoadError',
            label: 'Chunk Load Error',
            error: new ChunkLoadError({
                message: 'Chunk could not be loaded',
                chunkPath: 'js/chunk.js'
            })
        },
        {
            key: 'StackStraceError',
            label: 'Stack Trace Error',
            error: getErrorWithCustomStack('MyCustomStack')
        },
        {
            key: 'CaptchaServiceError',
            label: 'Captcha Service Error',
            error: new CaptchaServiceError()
        }
    ];
}
function getErrorWithCustomStack(customStack) {
    const error = new Error('Error');
    error.stack = customStack;
    return error;
}
