import { getLocationOrigin } from '@leon-hub/service-locator-env';
export function formatTime(diff) {
    if (diff < 1000) return `${Math.floor(diff)} ms`;
    return `${(diff / 1000).toFixed(2)} s`;
}
const MAX_NAME_LENGTH = 30;
export function minimize(name) {
    let max = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : MAX_NAME_LENGTH;
    if (name.length <= max) return name;
    const url = new URL(name);
    let shorterName = '';
    if (url.host) shorterName += url.host;
    if (url.pathname) shorterName += url.pathname;
    if (url.search) shorterName += url.search;
    return shorterName.slice(-max);
}
export function getDefaultPattern() {
    const basePath = '/' === __webpack_public_path__ ? getLocationOrigin() : __webpack_public_path__;
    return `${basePath.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/\/$/, '')}/js/`;
}
export function isInitialPerformanceResourceTiming(entry) {
    return 'script' === entry.initiatorType;
}
