import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0
};
import { unTypeEnv } from '@leon-hub/environment-common';
import { envPrefix, FeatureValue } from '@leon-hub/environment-features';
import getDefinedEnvironment from 'web/src/plugins/EnvironmentPlugin/getDefinedEnvironment';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FeatureFlagsTab',
    setup (__props) {
        const env = unTypeEnv(getDefinedEnvironment());
        const names = [];
        for (const key of Object.keys(env)){
            const value = env[key];
            if (key.startsWith(envPrefix) && value === FeatureValue.ENABLED) names.push(key);
        }
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, _renderList(names, (name)=>(_openBlock(), _createElementBlock(_Fragment, {
                    key: name
                }, [
                    name ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(name), 1)) : _createCommentVNode("", true)
                ], 64))), 64));
    }
});
