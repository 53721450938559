import { computed, onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { getDefaultPattern, isInitialPerformanceResourceTiming } from 'web/src/modules/dev-tools/components/PerformanceTab/utils';
export const usePerformanceTab = ()=>{
    const resourceTimingList = ref([]);
    const totalResourcesCount = computed(()=>resourceTimingList.value.length);
    const records = ref([]);
    const excludedResourcesCount = ref(0);
    const totalScores = ref({
        date: new Date().toTimeString(),
        totalSize: 0,
        initialSize: 0,
        asyncSize: 0,
        initialTime: 0,
        asyncTime: 0
    });
    const filterPattern = ref(getDefaultPattern());
    function updateResources() {
        const value = performance.getEntriesByType('resource').filter((entry)=>'resource' === entry.entryType);
        if (resourceTimingList.value.length !== value.length) resourceTimingList.value = value;
    }
    watchEffect(()=>{
        const re = new RegExp(filterPattern.value);
        const webpackPublicResourceTimings = resourceTimingList.value.filter((entry)=>re.test(entry.name));
        excludedResourcesCount.value = resourceTimingList.value.length - webpackPublicResourceTimings.length;
        records.value = webpackPublicResourceTimings.map((entry)=>{
            const isInitial = isInitialPerformanceResourceTiming(entry);
            return {
                name: entry.name,
                size: entry.transferSize ?? 0,
                time: entry.responseEnd - entry.startTime,
                async: !isInitial
            };
        });
        calculateTotals();
    });
    function calculateTotals() {
        totalScores.value.totalSize = records.value.reduce((sum, r)=>sum + r.size, 0) / 1024;
        totalScores.value.initialSize = records.value.filter((r)=>!r.async).reduce((sum, r)=>sum + r.size, 0) / 1024;
        totalScores.value.initialTime = records.value.filter((r)=>!r.async).reduce((sum, r)=>sum + r.time, 0);
        totalScores.value.asyncSize = records.value.filter((r)=>r.async).reduce((sum, r)=>sum + r.size, 0) / 1024;
        totalScores.value.asyncTime = records.value.filter((r)=>r.async).reduce((sum, r)=>sum + r.time, 0);
        totalScores.value.date = new Date().toLocaleTimeString();
    }
    watchEffect(updateResources);
    onMounted(()=>{
        const interval = setInterval(()=>{
            updateResources();
        }, 1000);
        onUnmounted(()=>clearInterval(interval));
    });
    return {
        excludedResourcesCount,
        totalResourcesCount,
        filterPattern,
        totalScores,
        records
    };
};
