import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "devtools"
};
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import { useDevtoolsRoutePage } from 'web/src/modules/dev-tools/pages/DevtoolsRoutePage/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DevtoolsRoutePage',
    setup (__props) {
        const { isDevIP, tabsProperties, setTab, activeComponent } = useDevtoolsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isDevIP) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _cache[0] || (_cache[0] = _createElementVNode("h2", null, "Developer Tools Page", -1)),
                _createVNode(VTabs, _mergeProps(_unref(tabsProperties), {
                    onTabClick: _unref(setTab)
                }), null, 16, [
                    "onTabClick"
                ]),
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(activeComponent))))
            ])), [
                [
                    _directive_auto_id,
                    'DevtoolsRoutePage'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
