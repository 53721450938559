import { isObject, isString, isArrayOfStrings } from '@leon-hub/guards';
export function isRouteData(value) {
    return isObject(value) && 'name' in value && isRouteDataName(value.name) && 'params' in value && isRouteDataParams(value.name);
}
function isRouteDataName(name) {
    return void 0 === name || [
        'string',
        'symbol'
    ].includes(typeof name);
}
function isRouteDataParams(params) {
    return isObject(params) && Object.values(params).every((param)=>isString(param) || isArrayOfStrings(param));
}
