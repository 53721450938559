import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__actions"
};
const _hoisted_2 = {
    class: "devtools__actions"
};
import { toRef } from 'vue';
import { useVirtualSportDebugStore } from 'web/src/modules/sportline/submodules/virtual-sport/store';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { TextInputTypes } from 'web/src/components/Input/enums';
import { TextInput } from 'web/src/components/Input';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VirtualSportTab',
    setup (__props) {
        const debugVirtualSportStore = useVirtualSportDebugStore();
        const betDelay = toRef(debugVirtualSportStore, 'betDelay');
        const iframeUrl = toRef(debugVirtualSportStore, 'iframeUrl');
        const { setBetDelay, saveBetDelay, setIFrameUrl, saveIFrameUrl } = debugVirtualSportStore;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_unref(TextInput), {
                        value: betDelay.value ? String(betDelay.value) : void 0,
                        type: _unref(TextInputTypes).NUMBER,
                        label: "Bet delay (ms)",
                        onChange: _cache[0] || (_cache[0] = ($event)=>_unref(setBetDelay)($event.target.value))
                    }, null, 8, [
                        "value",
                        "type"
                    ]),
                    _createVNode(VButton, {
                        kind: _unref(ButtonKind).YELLOW,
                        height: _unref(ButtonHeight).LARGE,
                        "full-width": false,
                        label: "Save",
                        class: "devtools__actions-button devtools__actions-input-button",
                        onClick: _unref(saveBetDelay)
                    }, null, 8, [
                        "kind",
                        "height",
                        "full-width",
                        "onClick"
                    ])
                ]),
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_unref(TextInput), {
                        value: iframeUrl.value ?? void 0,
                        label: "Custom IFrame url",
                        hint: "e.g. http://localhost:8089/iframe-widgets",
                        onChange: _cache[1] || (_cache[1] = ($event)=>_unref(setIFrameUrl)($event.target.value))
                    }, null, 8, [
                        "value"
                    ]),
                    _createVNode(VButton, {
                        kind: _unref(ButtonKind).YELLOW,
                        height: _unref(ButtonHeight).LARGE,
                        "full-width": false,
                        label: "Save",
                        class: "devtools__actions-button devtools__actions-input-button",
                        onClick: _unref(saveIFrameUrl)
                    }, null, 8, [
                        "kind",
                        "height",
                        "full-width",
                        "onClick"
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'VirtualSportTab'
                ]
            ]);
        };
    }
});
