import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__code"
};
const _hoisted_2 = {
    key: 1
};
import { onMounted, ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ServiceWorkerTab',
    setup (__props) {
        const swConfig = ref(null);
        onMounted(getSwConfig);
        async function getSwConfig() {
            "1";
            {
                const { getConfig } = await import('web/src/modules/service-worker/utils');
                swConfig.value = await getConfig();
            }
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                swConfig.value ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Configuration:", -1)),
                    _createElementVNode("pre", _hoisted_1, _toDisplayString(JSON.stringify(swConfig.value, null, 2)), 1)
                ], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_2, " SW Disabled! "))
            ])), [
                [
                    _directive_auto_id,
                    'ServiceWorkerTab'
                ]
            ]);
        };
    }
});
