import { onMounted, ref } from 'vue';
import { localStorageManager } from '@leon-hub/local-storage';
import { ApiConnectionError } from '@leon-hub/api';
import { logger } from '@leon-hub/logging';
import { Json, Timer } from '@leon-hub/utils';
import { AppIgnoredError } from '@leon-hub/app-errors';
import { getErrorButtonItems } from 'web/src/modules/dev-tools/components/ErrorsTab/composables/utils';
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
import { useRootStore } from 'web/src/modules/core/store';
export default function useErrorTab() {
    const apiConnectionErrors = localStorageManager.getItem('ApiConnectionErrors');
    const networkErrorsData = ref([]);
    const errorButtons = getErrorButtonItems();
    const snackbarsStore = useSnackbarsStore();
    const rootStore = useRootStore();
    onMounted(()=>{
        networkErrorsData.value = Json.parse(apiConnectionErrors || '[]') ?? [];
    });
    function onEmulateError(errorButton) {
        if ('UnhandledPromiseRejection' === errorButton.key) Timer.setTimeout(()=>{
            Promise.reject(errorButton.error);
        }, 0);
        else if ('ApiConnectionError' === errorButton.key) snackbarsStore.showConnectionError(false, new ApiConnectionError({
            operationName: 'DevToolsConnectionError'
        }));
        else throw errorButton.error;
    }
    function onEmulateVueError(isIgnored) {
        if (isIgnored) throw new AppIgnoredError();
        throw new Error('Vue Component Error');
    }
    function onEmulateNativeCrash() {}
    function onClearNetworkErrors() {
        localStorageManager.removeItem('ApiConnectionErrors');
        networkErrorsData.value = [];
    }
    function onSendErrorLog() {
        logger.setRemoteSaveAction(rootStore.log);
        logger.error('Devtools error', new ApiConnectionError({
            operationName: 'DevToolsConnectionError'
        }));
    }
    return {
        errorButtons,
        onEmulateError,
        onEmulateVueError,
        onEmulateNativeCrash,
        onSendErrorLog,
        networkErrorsData,
        onClearNetworkErrors
    };
}
