import { computed, ref } from 'vue';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import GeneralTab from 'web/src/modules/dev-tools/components/GeneralTab/GeneralTab.vue';
import DebugTab from 'web/src/modules/dev-tools/components/DebugTab/DebugTab.vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import CustomerTab from 'web/src/modules/dev-tools/components/CustomerTab/CustomerTab.vue';
import FeatureFlagsTab from 'web/src/modules/dev-tools/components/FeatureFlagsTab/FeatureFlagsTab.vue';
import CaptchaTab from 'web/src/modules/dev-tools/components/CaptchaTab/CaptchaTab.vue';
import EventsTab from 'web/src/modules/dev-tools/components/EventsTab/EventsTab.vue';
import ErrorsTab from 'web/src/modules/dev-tools/components/ErrorsTab/ErrorsTab.vue';
import TemplatesTab from 'web/src/modules/dev-tools/components/TemplatesTab/TemplatesTab.vue';
import TemplatesTab3 from 'web/src/modules/dev-tools/components/TemplatesTab3/TemplatesTab3.vue';
import { VEmpty } from 'web/src/components/Empty';
import BtrTab from 'web/src/modules/dev-tools/components/BtrTab/BtrTab.vue';
import LocalStorageTab from 'web/src/modules/dev-tools/components/LocalStorageTab/LocalStorageTab.vue';
import CookiesTab from 'web/src/modules/dev-tools/components/CookiesTab/CookiesTab.vue';
export default function useDevtoolsRoutePage() {
    const tabId = ref('General');
    const { isDevIP } = useIsDevIP();
    const { isLoggedIn } = useIsLoggedIn();
    const tabs = computed(()=>{
        const tabsArray = [
            {
                component: GeneralTab,
                options: {
                    id: 'General',
                    label: 'General'
                }
            },
            {
                component: DebugTab,
                options: {
                    id: 'Debug',
                    label: 'Debug'
                }
            }
        ];
        // eslint-disable-next-line max-len
        tabsArray.push({
            component: LocalStorageTab,
            options: {
                id: 'LocalStorage',
                label: 'LC'
            }
        }, {
            component: CookiesTab,
            options: {
                id: 'Cookies',
                label: 'Cookies'
            }
        });
        // eslint-disable-next-line max-len
        // eslint-disable-next-line max-len
        // eslint-disable-next-line max-len
        // eslint-disable-next-line max-len
        // eslint-disable-next-line max-len
        if (isLoggedIn.value) tabsArray.push({
            component: CustomerTab,
            options: {
                id: 'Customer',
                label: 'Customer'
            }
        });
        "1";
        tabsArray.push({
            component: require('web/src/modules/dev-tools/components/SeoTab/SeoTab.vue').default,
            options: {
                id: 'SEO',
                label: 'SEO'
            }
        });
        tabsArray.push({
            component: require('web/src/modules/dev-tools/components/FingerPrintTab/FingerPrintTab.vue').default,
            options: {
                id: 'FingerPrint',
                label: 'FingerPrint'
            }
        });
        "1";
        tabsArray.push({
            component: require('web/src/modules/dev-tools/components/ServiceWorkerTab/ServiceWorkerTab.vue').default,
            options: {
                id: 'SW',
                label: 'SW'
            }
        });
        tabsArray.push({
            component: require('web/src/modules/dev-tools/components/PerformanceTab/PerformanceTab.vue').default,
            options: {
                id: 'Performance',
                label: 'Perf'
            }
        });
        tabsArray.push({
            component: require('web/src/modules/dev-tools/components/AffiliatesTab/AffiliatesTab.vue').default,
            options: {
                id: 'Affiliates',
                label: 'Affiliates'
            }
        });
        tabsArray.push({
            component: require('web/src/modules/dev-tools/components/DeepLinksTab/DeepLinksTab.vue').default,
            options: {
                id: 'DeepLinks',
                label: 'Deep Links'
            }
        });
        tabsArray.push({
            component: require('web/src/modules/dev-tools/components/Sportline/SportlineTab.vue').default,
            options: {
                id: 'Sportline',
                label: 'Sportline'
            }
        });
        "1";
        tabsArray.push({
            component: require('web/src/modules/dev-tools/components/Sportline/VirtualSportTab.vue').default,
            options: {
                id: 'VirtualSport',
                label: 'Virtual Sport'
            }
        });
        tabsArray.push({
            component: CaptchaTab,
            options: {
                id: 'Captcha',
                label: 'Captcha'
            }
        }, {
            component: FeatureFlagsTab,
            options: {
                id: 'Feature Flags',
                label: 'Feature Flags'
            }
        }, {
            component: EventsTab,
            options: {
                id: 'Events',
                label: 'Events'
            }
        }, {
            component: ErrorsTab,
            options: {
                id: 'Errors',
                label: 'Errors'
            }
        }, {
            component: TemplatesTab,
            options: {
                id: 'Templates',
                label: 'Templates'
            }
        }, {
            component: TemplatesTab3,
            options: {
                id: 'Templates3',
                label: 'Templates3'
            }
        }, {
            component: BtrTab,
            options: {
                id: 'BTR',
                label: 'BTR'
            }
        });
        return tabsArray;
    });
    const activeTab = computed(()=>tabs.value.find((tab)=>tab.options.id === tabId.value));
    const activeTabId = computed(()=>activeTab.value?.options.id || 'General');
    const activeComponent = computed(()=>activeTab.value?.component ?? VEmpty);
    const tabsProperties = computed(()=>({
            items: tabs.value.map((tab)=>tab.options),
            activeId: activeTabId.value
        }));
    function setTab(id) {
        tabId.value = id;
    }
    return {
        isDevIP,
        tabsProperties,
        setTab,
        activeComponent
    };
}
