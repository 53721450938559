import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__code"
};
const _hoisted_2 = {
    key: 0
};
const _hoisted_3 = {
    class: "devtools__code"
};
const _hoisted_4 = {
    class: "devtools__code"
};
const _hoisted_5 = [
    "href"
];
const _hoisted_6 = {
    class: "devtools__code"
};
const _hoisted_7 = {
    class: "devtools__code"
};
const _hoisted_8 = {
    class: "devtools__code"
};
const _hoisted_9 = {
    class: "devtools__code"
};
const _hoisted_10 = {
    class: "devtools__code"
};
const _hoisted_11 = {
    class: "devtools__code"
};
const _hoisted_12 = {
    class: "devtools__code"
};
const _hoisted_13 = {
    key: 1
};
const _hoisted_14 = {
    key: 2
};
const _hoisted_15 = {
    key: 3
};
const _hoisted_16 = {
    key: 4
};
const _hoisted_17 = {
    class: "devtools__code"
};
import DropdownSelect from 'web/src/components/Select/components/DropdownSelect.vue';
import { useGeneralTab } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'GeneralTab',
    setup (__props) {
        const { stat, appChunkName, statUrl, localeOptions, locale, changeLocale, translation } = useGeneralTab();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", null, [
                    _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Version:", -1)),
                    _createElementVNode("code", _hoisted_1, _toDisplayString("6.100.0"), 1)
                ]),
                (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Stat:", -1)),
                    _createElementVNode("code", _hoisted_3, _toDisplayString(_unref(stat)), 1),
                    _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Stat URL:", -1)),
                    _createElementVNode("code", _hoisted_4, [
                        _createElementVNode("a", {
                            href: `${_unref(statUrl)}?nocache=${Math.random()}`
                        }, _toDisplayString(_unref(statUrl)), 9, _hoisted_5)
                    ]),
                    _cache[4] || (_cache[4] = _createElementVNode("h3", null, "App Chunk Name:", -1)),
                    _createElementVNode("code", _hoisted_6, _toDisplayString(_unref(appChunkName)), 1)
                ])),
                _createElementVNode("div", null, [
                    _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Product:", -1)),
                    _createElementVNode("code", _hoisted_7, _toDisplayString("li"), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Skin:", -1)),
                    _createElementVNode("code", _hoisted_8, _toDisplayString("default"), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Layout:", -1)),
                    _createElementVNode("code", _hoisted_9, _toDisplayString("desktop"), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[8] || (_cache[8] = _createElementVNode("h3", null, "Style:", -1)),
                    _createElementVNode("code", _hoisted_10, _toDisplayString("leon"), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Modernity:", -1)),
                    _createElementVNode("code", _hoisted_11, _toDisplayString('modern'), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Locale:", -1)),
                    _createVNode(DropdownSelect, {
                        class: "devtools__select",
                        name: "locale",
                        options: _unref(localeOptions),
                        "selected-value": _unref(locale),
                        "selected-label": _unref(locale),
                        onChange: _cache[0] || (_cache[0] = ($event)=>_unref(changeLocale)($event))
                    }, null, 8, [
                        "options",
                        "selected-value",
                        "selected-label"
                    ])
                ]),
                _createElementVNode("div", null, [
                    _cache[11] || (_cache[11] = _createElementVNode("h3", null, "OS:", -1)),
                    _createElementVNode("code", _hoisted_12, _toDisplayString(process.env.VUE_APP_OS), 1)
                ]),
                process.env.VUE_APP_OS_IOS ? (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[12] || (_cache[12] = [
                    _createElementVNode("h3", null, "OS IOS:", -1),
                    _createElementVNode("code", {
                        class: "devtools__code"
                    }, "OS IOS", -1)
                ]))) : _createCommentVNode("", true),
                process.env.VUE_APP_OS_MACOS ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[13] || (_cache[13] = [
                    _createElementVNode("h3", null, "OS MACOS:", -1),
                    _createElementVNode("code", {
                        class: "devtools__code"
                    }, "OS MACOS", -1)
                ]))) : _createCommentVNode("", true),
                process.env.VUE_APP_OS_ANDROID ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[14] || (_cache[14] = [
                    _createElementVNode("h3", null, "OS ANDROID:", -1),
                    _createElementVNode("code", {
                        class: "devtools__code"
                    }, "OS ANDROID", -1)
                ]))) : _createCommentVNode("", true),
                process.env.VUE_APP_OS_WINDOWS ? (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[15] || (_cache[15] = [
                    _createElementVNode("h3", null, "OS WINDOWS:", -1),
                    _createElementVNode("code", {
                        class: "devtools__code"
                    }, "OS WINDOWS", -1)
                ]))) : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                    _cache[16] || (_cache[16] = _createElementVNode("h3", null, "Translations:", -1)),
                    _createElementVNode("code", _hoisted_17, _toDisplayString(_unref(translation)), 1)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'GeneralTab'
                ]
            ]);
        };
    }
});
