import { ref } from 'vue';
import { disableAutomationPlugin, enableAutomationPlugin, isAutomationPluginEnabled } from '@leon-hub/directive-automation/plugin';
import { useRootStore } from 'web/src/modules/core/store';
export function useAutomationPluginToggler() {
    const automationPluginState = ref(isAutomationPluginEnabled(useRootStore().isDevIP));
    function toggleAutomationPluginState() {
        automationPluginState.value = !automationPluginState.value;
        if (automationPluginState.value) {
            if (enableAutomationPlugin()) window.location.reload();
        } else if (disableAutomationPlugin()) window.location.reload();
    }
    return {
        automationPluginState,
        toggleAutomationPluginState
    };
}
