import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__grid-fill"
};
const _hoisted_2 = {
    class: "devtools__code"
};
const _hoisted_3 = {
    class: "devtools__code"
};
import { toRef } from 'vue';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerTab',
    setup (__props) {
        const customerDataStore = useCustomerDataStore();
        const login = toRef(customerDataStore, 'login');
        const vipStatus = toRef(customerDataStore, 'vipStatus');
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Customer data:", -1)),
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", null, [
                        _cache[0] || (_cache[0] = _createElementVNode("h4", null, "Login:", -1)),
                        _createElementVNode("code", _hoisted_2, _toDisplayString(login.value), 1)
                    ]),
                    _createElementVNode("div", null, [
                        _cache[1] || (_cache[1] = _createElementVNode("h4", null, "VIP Status:", -1)),
                        _createElementVNode("code", _hoisted_3, _toDisplayString(vipStatus.value), 1)
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'CustomerTab'
                ]
            ]);
        };
    }
});
