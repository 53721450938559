import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_4 = {
    class: "devtools__code"
};
const _hoisted_5 = {
    class: "devtools__code"
};
import { onMounted, ref } from 'vue';
import { getCordovaAppConfig } from '@leon-hub/cordova';
import { useCookies } from 'web/src/modules/core/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliatesTab',
    setup (__props) {
        const cookieManager = useCookies();
        const { wm: wmBuiltin, qtag: qtagBuiltin } = getCordovaAppConfig();
        const wmCookie = ref('');
        const qtagCookie = ref('');
        onMounted(async ()=>{
            wmCookie.value = await cookieManager.get('wm') ?? '';
            qtagCookie.value = await cookieManager.get('qtag') ?? '';
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createCommentVNode("", true),
                _createElementVNode("div", null, [
                    _cache[2] || (_cache[2] = _createElementVNode("h4", null, "WM Cookie:", -1)),
                    _createElementVNode("code", _hoisted_4, _toDisplayString(wmCookie.value), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[3] || (_cache[3] = _createElementVNode("h4", null, "QTAG Cookie:", -1)),
                    _createElementVNode("code", _hoisted_5, _toDisplayString(qtagCookie.value), 1)
                ])
            ])), [
                [
                    _directive_auto_id,
                    'AffiliatesTab'
                ]
            ]);
        };
    }
});
