import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import TextInput from 'web/src/components/Input/components/TextInput/TextInput.vue';
import { formatTime } from './utils';
import { usePerformanceTab } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PerformanceTab',
    setup (__props) {
        const { records, totalScores, filterPattern, totalResourcesCount, excludedResourcesCount } = usePerformanceTab();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['resources-container'])
            }, [
                _createElementVNode("h2", {
                    class: _normalizeClass(_ctx.$style['title'])
                }, " Loaded Resources (" + _toDisplayString(_unref(totalResourcesCount)) + ") ", 3),
                _createVNode(TextInput, {
                    value: _unref(filterPattern),
                    label: "Regexp pattern",
                    onInput: _cache[0] || (_cache[0] = (param)=>{
                        let { target: { value } } = param;
                        return filterPattern.value = value;
                    }),
                    onChange: _cache[1] || (_cache[1] = (param)=>{
                        let { target: { value } } = param;
                        return filterPattern.value = value;
                    })
                }, null, 8, [
                    "value"
                ]),
                _createElementVNode("p", null, " Excluded resources: " + _toDisplayString(_unref(excludedResourcesCount)), 1),
                _createElementVNode("table", {
                    class: _normalizeClass(_ctx.$style['resources-table'])
                }, [
                    _createElementVNode("thead", null, [
                        _createElementVNode("tr", {
                            class: _normalizeClass(_ctx.$style['tabe__tr'])
                        }, [
                            _createElementVNode("th", {
                                class: _normalizeClass(_ctx.$style['table__th'])
                            }, " Filename ", 2),
                            _createElementVNode("th", {
                                class: _normalizeClass(_ctx.$style['table__th'])
                            }, " Size (KB) ", 2),
                            _createElementVNode("th", {
                                class: _normalizeClass(_ctx.$style['table__th'])
                            }, " Time ", 2)
                        ], 2)
                    ]),
                    _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(records), (record)=>(_openBlock(), _createElementBlock("tr", {
                                key: record.name,
                                class: _normalizeClass(_ctx.$style['table__tr'])
                            }, [
                                _createElementVNode("td", {
                                    class: _normalizeClass([
                                        _ctx.$style['table__td'],
                                        _ctx.$style['field--name']
                                    ])
                                }, _toDisplayString(record.name), 3),
                                _createElementVNode("td", {
                                    class: _normalizeClass(_ctx.$style['table__td'])
                                }, _toDisplayString((record.size / 1024).toFixed(2)), 3),
                                _createElementVNode("td", {
                                    class: _normalizeClass(_ctx.$style['table__td'])
                                }, _toDisplayString(_unref(formatTime)(record.time)), 3)
                            ], 2))), 128))
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['total-scores'])
                }, [
                    _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Total Scores", -1)),
                    _createElementVNode("p", null, "Total Size: " + _toDisplayString(_unref(totalScores).totalSize.toFixed(2)) + " KB", 1),
                    _createElementVNode("p", null, "Initial Size: " + _toDisplayString(_unref(totalScores).totalSize.toFixed(2)) + " KB", 1),
                    _createElementVNode("p", null, "Async Size: " + _toDisplayString(_unref(totalScores).asyncSize.toFixed(2)) + " KB", 1),
                    _createElementVNode("p", null, "Initial Time: " + _toDisplayString(_unref(formatTime)(_unref(totalScores).initialTime)), 1),
                    _createElementVNode("p", null, "Async Time: " + _toDisplayString(_unref(formatTime)(_unref(totalScores).asyncTime)), 1),
                    _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("p", null, "Updated: " + _toDisplayString(_unref(totalScores).date), 1)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PerformanceTab'
                ]
            ]);
        };
    }
});
