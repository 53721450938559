import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__grid-fill"
};
const _hoisted_3 = {
    key: 1
};
const _hoisted_5 = {
    class: "devtools__code"
};
const _hoisted_6 = {
    key: 3
};
const _hoisted_11 = {
    class: "devtools__code"
};
const _hoisted_12 = {
    key: 8
};
const _hoisted_18 = {
    class: "devtools__code"
};
import { getPlatform, getProduct, getSkin } from 'web/src/modules/dev-tools/components/TemplatesTab/utils/getTemplate';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TemplatesTab',
    setup (__props) {
        const platform = getPlatform();
        const product = getProduct();
        const skin = getSkin();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _cache[17] || (_cache[17] = _createElementVNode("h3", null, "Templates:", -1)),
                _createElementVNode("div", _hoisted_1, [
                    (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
                        _createElementVNode("h4", null, "VUE_APP_PLATFORM:", -1),
                        _createElementVNode("code", {
                            class: "devtools__code"
                        }, "WEB", -1)
                    ]))),
                    _createElementVNode("div", null, [
                        _cache[3] || (_cache[3] = _createElementVNode("h4", null, "VUE_APP_PLATFORM JS:", -1)),
                        _createElementVNode("code", _hoisted_5, _toDisplayString(_unref(platform)), 1)
                    ]),
                    (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[4] || (_cache[4] = [
                        _createElementVNode("h4", null, "VUE_APP_PRODUCT:", -1),
                        _createElementVNode("code", {
                            class: "devtools__code"
                        }, "LI", -1)
                    ]))),
                    _createElementVNode("div", null, [
                        _cache[9] || (_cache[9] = _createElementVNode("h4", null, "VUE_APP_PRODUCT JS:", -1)),
                        _createElementVNode("code", _hoisted_11, _toDisplayString(_unref(product)), 1)
                    ]),
                    (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[10] || (_cache[10] = [
                        _createElementVNode("h4", null, "VUE_APP_SKIN:", -1),
                        _createElementVNode("code", {
                            class: "devtools__code"
                        }, "DEFAULT", -1)
                    ]))),
                    _createElementVNode("div", null, [
                        _cache[16] || (_cache[16] = _createElementVNode("h4", null, "VUE_APP_SKIN JS:", -1)),
                        _createElementVNode("code", _hoisted_18, _toDisplayString(_unref(skin)), 1)
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'TemplatesTab'
                ]
            ]);
        };
    }
});
