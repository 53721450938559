import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "devtools__code"
};
const _hoisted_2 = {
    class: "devtools__actions"
};
import { ref, onMounted } from 'vue';
import { useCookies } from 'web/src/modules/core/composables';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { PresetName } from 'web/src/modules/dialogs/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CookiesTab',
    setup (__props) {
        const cookieManager = useCookies();
        const { showDialog } = useDialogs();
        const allCookies = ref([]);
        async function getAllCookies() {
            allCookies.value = await cookieManager.getAll();
        }
        async function clearCookies() {
            try {
                await cookieManager.clear();
                await getAllCookies();
                showDialog({
                    presetName: PresetName.ALERT_SUCCESS,
                    options: {
                        confirmMessage: 'OK'
                    }
                });
            } catch (rawError) {
                showDialog({
                    presetName: PresetName.ALERT_ERROR,
                    options: {
                        confirmMessage: `${rawError}`
                    }
                });
            }
        }
        onMounted(async ()=>{
            await getAllCookies();
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", _hoisted_1, [
                    _cache[0] || (_cache[0] = _createElementVNode("h4", null, "Cookies:", -1)),
                    _createElementVNode("pre", null, _toDisplayString(allCookies.value), 1)
                ]),
                _createElementVNode("div", null, [
                    _cache[1] || (_cache[1] = _createElementVNode("h4", null, "Clear cookies:", -1)),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(VButton, _mergeProps({
                            kind: _unref(ButtonKind).PRIMARY,
                            height: _unref(ButtonHeight).LARGE,
                            fullWidth: false
                        }, {
                            key: "clearCookies",
                            class: "devtools__actions-button",
                            label: "Clear cookies",
                            onClick: clearCookies
                        }), null, 16)
                    ])
                ])
            ])), [
                [
                    _directive_auto_id,
                    'CookiesTab'
                ]
            ]);
        };
    }
});
