import { computed, onMounted, ref } from 'vue';
import { DEBUG_KEY, disableDebug, enableDebug, isDebugEnabled } from '@leon-hub/debug';
import { isObject } from '@leon-hub/guards';
import { enableFormValidation, disableFormValidation, isFormValidationDisabled } from 'web/src/components/Form/components/VForm';
export default function useDebugTab() {
    const debugEnabledMap = ref({});
    const isValidationDisabled = ref(false);
    const isGeneralDebugEnabled = computed(()=>debugEnabledMap.value[DEBUG_KEY.GENERAL]);
    const isPostMessageBusDebugEnabled = computed(()=>debugEnabledMap.value[DEBUG_KEY.POST_MESSAGE]);
    const isAdvancedHttpRequestsDisabled = computed(()=>debugEnabledMap.value[DEBUG_KEY.ADVANCED_HTTP_REQUESTS_DISABLED]);
    onMounted(mounted);
    function mounted() {
        debugEnabledMap.value[DEBUG_KEY.GENERAL] = isDebugEnabled(DEBUG_KEY.GENERAL);
        debugEnabledMap.value[DEBUG_KEY.POST_MESSAGE] = isDebugEnabled(DEBUG_KEY.POST_MESSAGE);
        debugEnabledMap.value[DEBUG_KEY.ADVANCED_HTTP_REQUESTS_DISABLED] = isDebugEnabled(DEBUG_KEY.ADVANCED_HTTP_REQUESTS_DISABLED);
        isValidationDisabled.value = isFormValidationDisabled();
    }
    function toggleDebugMode(key) {
        if (debugEnabledMap.value[key]) {
            debugEnabledMap.value[key] = false;
            disableDebug(key);
        } else {
            debugEnabledMap.value[key] = true;
            enableDebug(key);
        }
        window.location.reload();
    }
    function toggleGeneralDebugMode() {
        toggleDebugMode(DEBUG_KEY.GENERAL);
    }
    function toggleAdvancedHttpRequests() {
        toggleDebugMode(DEBUG_KEY.ADVANCED_HTTP_REQUESTS_DISABLED);
    }
    function togglePostMessageDebugMode() {
        toggleDebugMode(DEBUG_KEY.POST_MESSAGE);
    }
    function toggleFormValidationMode() {
        if (isValidationDisabled.value) {
            isValidationDisabled.value = false;
            enableFormValidation();
        } else {
            isValidationDisabled.value = true;
            disableFormValidation();
        }
        window.location.reload();
    }
    const isVueDevToolEnabled = ref(false);
    function enableVueDevTools() {
        const vueAppKey = '__vue_app__';
        const elements = Array.from(document.querySelectorAll('*'));
        const element = elements.find((el)=>vueAppKey in el);
        const app = element[vueAppKey];
        isObject(app);
        const { version } = app;
        // eslint-disable-next-line no-underscore-dangle
        const devtools = window.__VUE_DEVTOOLS_GLOBAL_HOOK__;
        isObject(devtools) && devtools.emit;
        devtools.enabled = true;
        devtools.emit('app:init', app, version, {});
        isVueDevToolEnabled.value = true;
    }
    return {
        isGeneralDebugEnabled,
        isPostMessageBusDebugEnabled,
        isValidationDisabled,
        isVueDevToolEnabled,
        isAdvancedHttpRequestsDisabled,
        toggleGeneralDebugMode,
        togglePostMessageDebugMode,
        toggleFormValidationMode,
        toggleAdvancedHttpRequests,
        enableVueDevTools
    };
}
