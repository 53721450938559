export function getPlatform() {
    "1";
    return 'WEB';
}
export function getProduct() {
    "1";
    return 'LI';
}
export function getSkin() {
    "1";
    return 'DEFAULT';
}
